import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const ViewProjects = () => {
  return (
    <LayoutComponent>
      <h4>View All Projects</h4>

      <div>
        <p>
          This page displays a list of all projects that have been created by
          the organization administrator or assigned to a user. Depending on the
          user’s role, different actions can be performed on the projects.
        </p>

        <h6>For Users:</h6>
        <p>
          Users can view all the projects they have been assigned to. This gives
          them access to relevant information and resources for their assigned
          projects, such as test cases, test cycles, and issues.
        </p>

        <h6>For Administrators:</h6>
        <p>
          Administrators have additional management options for the projects
          they created:
        </p>
        <ul>
          <li>
            <strong>Edit Projects</strong>: Admins can modify project details
            such as the name, description, or link an external project.
          </li>
          <li>
            <strong>Delete Projects</strong>: Admins have the ability to delete
            projects from the platform if they are no longer needed.
          </li>
          <li>
            <strong>Add Users to Projects</strong>: Admins can assign new users
            to a project, allowing them to access and collaborate on the
            project.
          </li>
        </ul>

        <p>
          This page serves as a central location for both users and admins to
          manage and view all relevant projects, streamlining collaboration and
          project management within the platform.
        </p>
      </div>
    </LayoutComponent>
  )
}

export default ViewProjects
